import React from 'react';



function Contact() {
  return (
    <div id="contact">

      <h2>Contact</h2>
      <p>snphillips (at) gmail (dot) com</p>
      <p>instagram: @snphillips</p>

    </div>
  );
}

export default Contact;

      // <p>facebook: @SarahNicolePhillips</p>
